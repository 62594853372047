import "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
//
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import '@/styles/vab.scss';

// 数据字典
import dict from "./components/Dict";

// 权限指令
import checkPer from '@/utils/permission';
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';

// 列表滚动
import scroll from 'vue-seamless-scroll';
Vue.use(scroll);
import App from "./App";
import store from "./store";
import router from "./router/routers";
import "./assets/icons"; // icon
import "./router/index"; // permission control
import 'echarts-gl';
import lodash from 'lodash';
// 打印
import Print from 'vue-print-nb';
Vue.use(Print); //注册
import '@/utils/flexible.js';
// 图片预览组件
import ImagePreview from "@/components/ImagePreview";
Vue.component("ImagePreview", ImagePreview);

// 时间格式化
// 引入
import moment from 'moment';
// 汉化，否则显示日期格式是国外的日期格式
moment.locale('zh-CN');
Vue.prototype.$lodash = lodash;
Vue.use(checkPer);
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(dict);
Vue.prototype.$moment = moment;
Vue.use(Element, {
  size: Cookies.get('size') || 'small' // set element-ui default size
});

Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});