import request from '@/utils/request';
import { encrypt } from "../../utils/aes";
export function get(projectName) {
  var params = {
    projectName: projectName,
    page: 0,
    size: 9999
  };
  return request({
    url: 'api/project/list',
    method: 'get',
    params: params
  });
}
export function getProjectDetail(prjId) {
  var aesId = prjId ? encodeURIComponent(encrypt(prjId)) : '';
  return request({
    url: "api/project/info?projectId=".concat(aesId),
    method: 'get'
  });
}
export function addProject(data) {
  return request({
    url: 'api/project',
    method: 'post',
    data: data
  });
}
export function addBusiness(data) {
  return request({
    url: '/api/project/update-project-business',
    method: 'post',
    data: data
  });
}
export function addServe(data) {
  return request({
    url: 'api/project/update-project-serve',
    method: 'post',
    data: data
  });
}
export function addTraffic(data) {
  return request({
    url: 'api/project/update-project-traffic',
    method: 'post',
    data: data
  });
}
export function addOperate(data) {
  return request({
    url: 'api/project/update-project-operate',
    method: 'post',
    data: data
  });
}
export function addWxpay(data) {
  return request({
    url: 'api/project/update-project-wxpay',
    method: 'post',
    data: data
  });
}
export function addpart(data) {
  return request({
    url: 'api/project/update-project-part',
    method: 'post',
    data: data
  });
}
export function del(data) {
  return request({
    url: 'api/project',
    method: 'delete',
    data: data
  });
}
export function delServe(id) {
  return request({
    url: "api/project/delete-project-serve/".concat(id),
    method: 'delete'
  });
}
export function delBusiness(id) {
  return request({
    url: "api/project/delete-project-business/".concat(id),
    method: 'delete'
  });
}
export function delTraffic(id) {
  return request({
    url: "api/project/delete-project-traffic/".concat(id),
    method: 'delete'
  });
}
export function delOperate(data) {
  return request({
    url: "api/project/delete-project-operate",
    method: 'delete',
    data: data
  });
}
export function delWxpay(id) {
  return request({
    url: "api/project/delete-project-wxpay/".concat(id),
    method: 'delete'
  });
}
export function delpart(id) {
  return request({
    url: "api/project/delete-project-part/".concat(id),
    method: 'delete'
  });
}
export function edit(data) {
  return request({
    url: 'api/project',
    method: 'put',
    data: data
  });
}
export function editServe(data) {
  return request({
    url: 'api/project/update-project-serve',
    method: 'POST',
    data: data
  });
}
export function editBusiness(data) {
  return request({
    url: 'api/project/update-project-business',
    method: 'POST',
    data: data
  });
}
export function editTraffic(data) {
  return request({
    url: 'api/project/update-project-traffic',
    method: 'POST',
    data: data
  });
}
export function uploadProjectPicture(data) {
  return request({
    url: 'api/project/upload',
    ContentType: 'multipart/form-data',
    method: 'post',
    data: data
  });
}
export function uploadAddPicture(data) {
  return request({
    url: 'api/file/upload',
    ContentType: 'multipart/form-data',
    method: 'post',
    data: data
  });
}
export function getDataForDict(params) {
  return request({
    url: '/api/dictDetail/dictDetail',
    method: 'get',
    params: params
  });
}
export function editProjectStatus(data) {
  return request({
    url: '/api/project/update-status',
    method: 'PUT',
    data: data
  });
}
export function getOperateType(id) {
  return request({
    url: "/api/project/get-operateType-by-project-id/".concat(id),
    method: 'get'
  });
}

// 新增项目模板
export function addTemplate(data) {
  return request({
    url: '/api/project/update-project-template',
    method: 'post',
    data: data
  });
}

// 修改项目模板
export function editTemplate(data) {
  return request({
    url: '/api/project/update-project-template',
    method: 'post',
    data: data
  });
}

// 删除项目模板
export function delTemplate(id) {
  return request({
    url: "/api/project/delete-project-template/".concat(id),
    method: 'POST'
  });
}
// 获取类型为小区的项目
export function getCommunityProjectList(id) {
  return request({
    url: "/api/project/get-community-list",
    method: 'GET'
  });
}
export default {
  get: get,
  addProject: addProject,
  addBusiness: addBusiness,
  addServe: addServe,
  addTraffic: addTraffic,
  addOperate: addOperate,
  addWxpay: addWxpay,
  addpart: addpart,
  addTemplate: addTemplate,
  edit: edit,
  editServe: editServe,
  editBusiness: editBusiness,
  editTraffic: editTraffic,
  editTemplate: editTemplate,
  del: del,
  delServe: delServe,
  delBusiness: delBusiness,
  delTraffic: delTraffic,
  delOperate: delOperate,
  delWxpay: delWxpay,
  delTemplate: delTemplate,
  delpart: delpart,
  getProjectDetail: getProjectDetail,
  uploadProjectPicture: uploadProjectPicture,
  getDataForDict: getDataForDict,
  getOperateType: getOperateType,
  getCommunityProjectList: getCommunityProjectList
};