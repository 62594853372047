import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import IconBtn from "./IconBtn";
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import Search from '@/components/HeaderSearch';
import Avatar from '@/assets/images/avatar.png';
import store from '@/store';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    IconBtn: IconBtn,
    Hamburger: Hamburger,
    Screenfull: Screenfull,
    SizeSelect: SizeSelect,
    Search: Search
  },
  data: function data() {
    return {
      Avatar: Avatar,
      dialogVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    },
    showModel: {
      get: function get() {
        return this.$store.state.settings.showModel;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeModel', {
          key: 'showModel',
          value: val
        });
      }
    }
  }),
  created: function created() {
    var _this = this;
    store.dispatch('GetInfo').then(function (res) {
      _this.username = res.userInfo.realName;
    });
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
      console.log(this);
    },
    open: function open() {
      var _this2 = this;
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.logout();
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        window.location.href = '/';
        // location.reload();
      });
    }
  }
};