var decryptWhiteList = {
  '/app/bill/list': ['totalMoney', 'invoiceNo', 'payee', 'payeeAccount', 'payeeBank'],
  '/app/repair/detail': ['empPhone', 'empName', 'managerName', 'managerPhone', 'repairWorkerName', 'repairWorkerPhone'],
  'api/largeScreen/getLargeScreenRepair': ['repairWorkerName', 'empName', 'empPhone', 'leaseName'],
  'api/dashboard/get-dashboard-clean-list': ['realName'],
  '/app/dashboard/get-dashboard-clean-list': ['realName'],
  '/app/home/get-user-info': ['name', 'mobile', 'companyName'],
  '/app/repair/allList': ['leaseName', 'repairAddress', 'empName', 'empPhone', 'repairWorkerName'],
  '/app/property/messageList': ['createBy', 'userName', 'userPhone', 'leaseName'],
  '/app/inspect/get-task-list': ['taskName'],
  'api/dashboard/get-dashboard-engineer-list': ['taskName', 'realName'],
  'api/inspectPlan/pageList': ['planName', 'userNames', 'deptNames'],
  'api/largeScreen/getLargeScreenSysNews': ['createBy', 'userName', 'leaseName'],
  '/app/largeScreen/getLargeScreenSysNews': ['createBy', 'userName', 'leaseName'],
  'api/inspectTask/pageList': ['deptName', 'userName', 'name'],
  'api/statements/getAttendAbsence': ['real_name'],
  '/app/statements/getAttendAbsence': ['real_name'],
  'api/statements/getAbsenceUser': ['realName'],
  '/app/statements/getAbsenceUser': ['realName'],
  'api/statements/getAttendDataUser': ['realName'],
  '/app/statements/getAttendDataUser': ['realName'],
  '/app/project/get-project-news': ['content'],
  'api/repair/list': ['empName', 'empPhone', 'repairWorkerName', 'leaseName'],
  '/app/repair/myList': ['leaseName', 'empName', 'empPhone', 'repairWorkerName'],
  '/mini-program/auth/mobile-login': ['name', 'mobile', 'email', 'realName', 'userName'],
  '/app/repair/getRepairWorker': ['userName', 'realName', 'email', 'phone', 'lineManagerName', 'projectLeaderName'],
  '/app/repair/getManger': ['userName', 'realName', 'email', 'phone', 'lineManagerName', 'projectLeaderName'],
  '/auth/info': ['username', 'realName', 'mobile', 'employeeName', 'companyName', 'userName', 'deptName', 'phone', 'ownerCompany'],
  '/auth/phoneLogin': ['mobile', 'userName', 'realName', 'companyName', 'username', 'employeeName', 'deptName', 'phone', 'ownerCompany'],
  'api/dictDetail/dictDetail': ['value', 'createBy'],
  'api/project/get-project-list': ['remark', 'propertyManage', 'province', 'address'],
  'api/department/getDepartmentByUser': ['updateBy', 'mobile', 'realName', 'userName', 'createBy'],
  'api/department/getDeptTree': ['createBy', 'deptName', 'deptIdChain'],
  'api/inspectTask/getPoi': ['userName'],
  '/app/inspect/get-task-info': ['userName', 'poiName'],
  'api/sys-position/query-user': ['realName', 'userName'],
  '/app/employee/get_company': ['createBy', 'lesseeFullname', 'companyName', 'payNoticeAddr', 'payNoticeTitle'],
  '/app/inspect/get-poi-by-code': ['poiName', 'buildName'],
  '/auth/login': ['mobile', 'userName', 'realName', 'companyName', 'username', 'employeeName', 'deptName', 'phone', 'ownerCompany'],
  '/app/attendance/dayStatistics': ['userName'],
  'api/statements/getWorkerStatistics': ['real_name'],
  'api/project/all': ['remark', 'propertyManage', 'phone', 'province', 'city', 'address', 'region'],
  'api/statements/getWorkerTrend': ['real_name'],
  'api/statements/getExportWorkerStatistics': ['real_name'],
  'api/workSchedule/pageList': ['userName', 'createBy'],
  'api/engineering/report/get-user-inspect-number': ['realName'],
  'api/msg/list': ['createBy', 'leaseName'],
  'api/employees': ['name', 'userName', 'projectName'],
  'api/account/getManger': ['realName'],
  'api/workSchedule/list': ['userName', 'deptName'],
  'api/classes/list': ['createBy'],
  'api/department/getAllDept': ['createBy', 'mobile', 'realName', 'updateBy', 'deptName'],
  'api/account': ['ownerCompany', 'propertyManage', 'userName', 'email', 'name', 'realName', 'mobile'],
  '/app/employee/switch-account-list': ['realName', 'mobile', 'userName'],
  '/app/employee/info': ['mobile', 'propertyManage', 'phone', 'userName', 'realName'],
  'api/lessee/list': ['lesseeFullname', 'phone', 'companyName'],
  'api/bill/list': ['tenantName'],
  'api/billItem/list': ['tenantName', 'payee', 'payNoticeTitle', 'createBy'],
  'api/lessee/getByProjectId': [],
  'api/cockpitIndex/getJobSecurityStaffDetail': ['lesseeFullname', 'adminPerson', 'phone', 'companyName', 'email'],
  'api/cockpit-repair/project-repair-summary': ['projectName'],
  'api/repair/log': ['flowName', 'remark', 'createBy'],
  'api/project/list': ['propertyManage', 'province', 'phone', 'city', 'remark'],
  'api/project/get-allproject-list': ['propertyManage', 'province', 'phone', 'city', 'remark'],
  'api/projectBuild/info': ['buildName', 'createBy', 'updateBy'],
  'api/projectSpaceUnit/list': ['createBy'],
  'api/energy': ['createBy'],
  'api/sysNews/list': ['createBy'],
  'api/msgSend/list': ['createBy', 'userName', 'userPhone'],
  '/app/home/getAllBook': ['createBy', 'updateBy', 'mobile', 'realName', 'userName'],
  '/app/msgSend/listSerivce': ['createBy', 'buildName'],
  'api/department/getDeptByUser': ['deptIdChain', 'createBy', 'mobile', 'updateBy', 'realName', 'userName'],
  'api/device/getByPidDevice': ['mac'],
  'api/program': ['createBy', 'updateBy'],
  'api/department/getDeptUser': ['userName', 'deptIdChain', 'mobile'],
  '/app/workSchedule/myList': ['userName', 'createBy'],
  'api/project/allShangxian': ['remark', 'propertyManage'],
  'api/lessee/info': ['lesseeFullname', 'createBy', 'userName'],
  'api/employees/getAdminByLesseeId': ['name', 'mobile', 'userName', 'email', 'adminPerson', 'phone'],
  'api/account/getRepairWorker': ['realName', 'email', 'userName'],
  '/app/advise/getByAllProjectId': ['phone', 'lesseeFullname', 'adminPerson', 'companyName'],
  '/app/reportForms/getWorkerTrend': ['real_name'],
  '/app/attendance/getAttendData': ['real_name'],
  'api/department/getDeptUserByDeptIds': ['userName', 'mobile', 'deptIdChain'],
  'api/classes/get-list-by-deptId': ['createBy'],
  'api/poi/getForm': ['createBy', 'updateBy'],
  'api/fromList/info': ['createBy', 'updateBy'],
  'api/account/getSysUserInfo': ['propertyManage', 'ownerCompany', 'phone', 'mobile', 'email', 'name'],
  'api/account/get-dept-project': ['remark', 'propertyManage'],
  'api/inspectTask/get-user-floor-info': ['poiName'],
  'api/project/info': ['ownerCompany', 'propertyManage', 'address', 'province', 'city', 'region', 'openBank', 'bankAccount'],
  'api/account/getUserByProjectIds': ['mobile', 'realName', 'userName', 'email', 'projectLeaderName', 'lineManagerName']
};
export { decryptWhiteList };