import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);
export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/retrieve',
  component: function component(resolve) {
    return require(['@/views/retrieve'], resolve);
  },
  hidden: true
}, {
  path: '/bigDashboard',
  component: function component(resolve) {
    return require(['@/views/bigHome'], resolve);
  },
  hidden: true
}, {
  path: '/cockpitDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/cockpitIndex'], resolve);
  },
  hidden: true
}, {
  path: '/billDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/billChart'], resolve);
  },
  hidden: true
}, {
  path: '/repairDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/repairChart'], resolve);
  },
  hidden: true
}, {
  path: '/workDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/workChart'], resolve);
  },
  hidden: true
}, {
  path: '/cleaningDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/cleaningChart'], resolve);
  },
  hidden: true
}, {
  path: '/projectDashboard',
  component: function component(resolve) {
    return require(['@/views/cockpit/projectChart'], resolve);
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    hidden: true,
    name: 'Dashboard',
    meta: {
      title: '大屏',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}];
export default new Router({
  mode: 'hash',
  // mode: "history",
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  // base: process.env.BASE_URL,
  routes: constantRouterMap
});