import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
export default {
  name: "ImagePreview",
  props: {
    src: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: ""
    },
    height: {
      type: [Number, String],
      default: ""
    }
  },
  computed: {
    realSrc: function realSrc() {
      if (!this.src) {
        return;
      }
      var real_src = this.src.split(",")[0];
      return real_src;
    },
    realSrcList: function realSrcList() {
      if (!this.src) {
        return;
      }
      var real_src_list = this.src.split(",");
      var srcList = [];
      real_src_list.forEach(function (item) {
        return srcList.push(item);
      });
      return srcList;
    },
    realWidth: function realWidth() {
      return typeof this.width == "string" ? this.width : "".concat(this.width, "px");
    },
    realHeight: function realHeight() {
      return typeof this.height == "string" ? this.height : "".concat(this.height, "px");
    }
  }
};