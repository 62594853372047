export default {
  name: 'QrCode',
  data: function data() {
    return {
      tabIndex: 0,
      tabs: [{
        name: '小程序'
      }, {
        name: '公众号'
      }]
    };
  },
  computed: {
    showModel: {
      get: function get() {
        return this.$store.state.settings.showModel;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeModel', {
          key: 'showModel',
          value: val
        });
      }
    }
  },
  methods: {
    changeTab: function changeTab(index) {
      this.tabIndex = index;
    }
  }
};