import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
export default {
  name: 'AppMain',
  data: function data() {
    return {
      showFooter: true,
      showTags: true
    };
  },
  watch: {
    $route: function $route() {
      this.handleRouteChange();
    }
  },
  computed: {
    cachedViews: function cachedViews() {
      var arr = ['addFloor', 'addProject', 'sendRecords', 'addLessee', 'payNoticeDetail', 'classesDetail'];
      return arr;
    },
    // 这个方法用于缓存部分页面，只有list列表页需要缓存
    visitedViews: function visitedViews() {
      var _this = this;
      var arr = [];
      this.$store.state.tagsView.visitedViews.forEach(function (e) {
        console.log('🎄line:50', e);
        arr.push(_this.getTitle(e.title));
      });
      return arr;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    this.handleRouteChange();
  },
  activated: function activated() {
    this.handleRouteChange();
  },
  methods: {
    handleRouteChange: function handleRouteChange() {
      var path = this.$route.path;
      this.showFooter = !['/cockpit/index'].includes(path);
      console.log('🚀2024-04-08 14:10:16 AppMain.vue line:72', path);
      this.showTags = ['/cockpit/index'].includes(path) || ['/home/index'].includes(path) || ['/dashboard'].includes(path);
      console.log('🚀2024-04-08 14:10:31 AppMain.vue line:77', this.showTags);
    },
    getTitle: function getTitle(data) {
      var name = '';
      switch (data) {
        // 报表
        case '报修报表':
          name = 'reportList';
          break;
        // 工作流
        case '工作流':
          name = 'workflow';
          break;
        // 系统管理
        case '菜单管理':
          name = 'Menu';
          break;
        case '通用字典':
          name = 'dictList';
          break;
        case '角色权限管理':
          name = 'rolesList';
          break;
        case '用户组管理':
          name = 'sysUserGroupList';
          break;
        case '系统用户管理':
          name = 'accountList';
          break;
        case '日志管理':
          name = 'Log';
          break;
        case '审批流管理':
          name = 'approvalProcessList';
          break;

        // 项目信息管理
        case '项目管理':
          name = 'projectList';
          break;
        case '楼宇管理':
          name = 'projectBuildList';
          break;
        case '车位管理':
          name = 'projectCarportList';
          break;
        case '楼层管理':
          name = 'floorList';
          break;

        // 信息公告
        case '活动管理':
          name = 'activityList';
          break;
        case '公告管理':
          name = 'sysNewsList';
          break;
        case '集团资源管理':
          name = 'groupResourceList';
          break;
        case '消息模板通知管理':
          name = 'msgTemplateList';
          break;
        case '消息发送管理':
          name = 'msgSendList';
          break;

        // 租赁服务
        case '租户管理':
          name = 'lesseeList';
          break;
        case '人员管理':
          name = 'employeesList';
          break;
        case '访客邀约管理':
          name = 'visitList';
          break;
        case '常用服务申请管理':
          name = 'overtimeList';
          break;
        case '常用服务设置':
          name = 'applySettingList';
          break;
        case '月租车管理':
          name = 'parkApplyList';
          break;
        case '微信商户付款明细':
          name = 'wxpaymentList';
          break;
        case '付款通知书管理':
          name = 'billList';
          break;
        case '账单管理':
          name = 'BillItemList';
          break;
        case '物业报修':
          name = 'repairList';
          break;

        // 客诉建议
        case '客诉管理':
          name = 'adviseFalseList';
          break;
        case '建议墙管理':
          name = 'adviseTrueList';
          break;
        case '能耗管理':
          name = 'energyManageList';
          break;
        // 组织架构
        case '部门管理':
          name = 'departmentList';
          break;
        case '组织人员':
          name = 'structrueList';
          break;
        // 排班管理
        case '班次管理':
          name = 'classesList';
          break;
        case '排班详情':
          name = 'ScheduleList';
          break;
        // 考勤管理
        case '考勤记录':
          name = 'recordList';
          break;
        case '考勤审批':
          name = 'approvalList';
          break;
        case '我的考勤':
          name = 'myRecordList';
          break;
        // 巡检管理
        case '设备资产管理':
          name = 'equipmentList';
          break;
        case '平面图管理':
          name = 'plan';
          break;
        case 'POI管理':
          name = 'PoiList';
          break;
        case '任务详情':
          name = 'taskDetailList';
          break;
        case '路线管理':
          name = 'routeList';
          break;
        case '计划管理':
          name = 'planList';
          break;
        case '任务中心':
          name = 'TaskList';
          break;
        case '人员轨迹':
          name = 'trajectory';
          break;
        case '轨迹分析':
          name = 'timeWorkChartList';
          break;
      }
      return name;
    }
  }
};