import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';
export default {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var permissions = store.getters && store.getters.permissions;
    if (value && value instanceof Array) {
      if (value.length > 0) {
        var hasPermission = permissions.some(function (permission) {
          return value.includes(permission);
        });
        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};