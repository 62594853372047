var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "app-main",
    class: {
      showFooter: _vm.showFooter,
      "pd-84": !_vm.showTags,
      "pd-52": _vm.showTags
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      exclude: _vm.cachedViews,
      include: _vm.visitedViews
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };