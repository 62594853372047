// import Logo from "@/assets/images/logo.png";
import Logo from '@/assets/images/logo-text.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '瑞仕会',
      logo: Logo
    };
  }
};