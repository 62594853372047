import request from '@/utils/request';
import { encrypt } from '@/utils/rsaEncrypt';
export function login(username, password, uuid) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      // code,
      uuid: uuid
    }
  });
}
export function getInfo() {
  return request({
    url: 'auth/info',
    method: 'get'
  });
}

// export function getCodeImg() {
//   return request({
//     url: 'auth/code',
//     method: 'get'
//   })
// }

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  });
}
export function phoneLogin(mobile, code) {
  return request({
    url: 'auth/phoneLogin',
    method: 'post',
    data: {
      mobile: mobile,
      code: code
    }
  });
}
export function sendMsg(mobile) {
  return request({
    url: "auth/sendMsg?mobile=".concat(mobile),
    method: 'get'
  });
}
export function wxCodeLogin(code) {
  var params = {
    code: code
  };
  return request({
    url: 'auth/wxCode',
    method: 'get',
    params: params
  });
}
export function idLogin(data) {
  return request({
    url: 'auth/idLogin',
    method: 'post',
    data: data
  });
}

// 根据手机号获取账号
export function getUserApi(mobile) {
  return request({
    url: "/mini-api/common/get-account-list?mobile=".concat(mobile),
    method: 'get'
  });
}

// 出勤概括
export function getAttendance() {
  return request({
    url: '/api/largeScreen/getAttendance',
    method: 'get'
  });
}

// 投诉占比
export function getLargeScreenAdvise(params) {
  return request({
    url: '/api/largeScreen/getLargeScreenAdvise',
    method: 'get',
    params: params
  });
}

// 巡检点位
export function getLargeScreenInspectTask(params) {
  return request({
    url: '/api/largeScreen/getLargeScreenInspectTask',
    method: 'get',
    params: params
  });
}

// 报修类型占比
export function getLargeScreenRepair(params) {
  return request({
    url: '/api/largeScreen/getLargeScreenRepair',
    method: 'get',
    params: params
  });
}

// 获取温馨提示
export function getLargeScreenWarmNotice(params) {
  return request({
    url: '/api/largeScreen/getLargeScreenWarmNotice',
    method: 'get',
    params: params
  });
}

// 获取事件报告
export function getLargeScreenSysNews(params) {
  return request({
    url: '/api/largeScreen/getLargeScreenSysNews',
    method: 'get',
    params: params
  });
}

// 获取收缴率
export function getBillCollection(params) {
  return request({
    url: '/api/largeScreen/getBillCollection',
    method: 'get',
    params: params
  });
}

// 保安title
export function getSecurityTitle(data) {
  data['routeType'] = 'ASKARI_INSPECT';
  return request({
    url: '/api/dashboard/get-dashboard-security',
    method: 'post',
    data: data
  });
}

// 保安List
export function getSecurityList(data) {
  data['routeType'] = 'ASKARI_INSPECT';
  return request({
    url: '/api/dashboard/get-dashboard-security-list',
    method: 'post',
    data: data
  });
}

// 保洁title
export function getCleanTitle(data) {
  data['routeType'] = 'CLERANING_WORK';
  return request({
    url: '/api/dashboard/get-dashboard-clean',
    method: 'post',
    data: data
  });
}

// 保洁List
export function getCleanList(data) {
  data['routeType'] = 'CLERANING_WORK';
  return request({
    url: '/api/dashboard/get-dashboard-clean-list',
    method: 'post',
    data: data
  });
}

// 工程title
export function getEngineerTitle(data) {
  data['routeType'] = 'ENGINEE_INSPECT';
  return request({
    url: '/api/dashboard/get-dashboard-engineering',
    method: 'post',
    data: data
  });
}

// 工程List
export function getEngineerList(data) {
  console.log('🚀2024-01-30 09:53:25 login.js line:200', data);
  data['routeType'] = 'ENGINEE_INSPECT';
  return request({
    url: '/api/dashboard/get-dashboard-engineer-list',
    method: 'post',
    data: data
  });
}

// 能耗管理
export function getEnergyList(params) {
  return request({
    url: '/api/largeScreen/getEnergyList',
    method: 'get',
    params: params
  });
}

// 24小时完工率
export function getFinishRateRepair(params) {
  return request({
    url: '/api/largeScreen/getFinishRateRepair',
    method: 'get',
    params: params
  });
}

// 车位
export function getParkNumberApi(params) {
  return request({
    url: '/api/largeScreen/getParkNumber',
    method: 'get',
    params: params
  });
}