var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footersty"
  }, [_c("p", [_c("a", {
    attrs: {
      href: "https://beian.miit.gov.cn/",
      target: "_Blank"
    }
  }, [_vm._v("沪ICP备2023010887号")]), _vm._v(" "), _c("img", {
    attrs: {
      src: require("../../assets/images/gonganbeian.png")
    }
  }), _vm._v(" "), _c("a", {
    attrs: {
      href: "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2023010887",
      target: "_Blank"
    }
  }, [_vm._v("沪公网安备 2023010887号")]), _vm._v(" "), _c("a", {
    attrs: {
      href: "http://www.sgs.gov.cn/lz/licenseLink.do?method=licenceView&entyId=2023010887",
      target: "_Blank"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../assets/images/gongshangicon.gif")
    }
  })])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };