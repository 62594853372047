var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-image", {
    style: "width:".concat(_vm.realWidth, ";height:").concat(_vm.realHeight, ";"),
    attrs: {
      src: "".concat(_vm.realSrc),
      fit: "cover",
      "preview-src-list": _vm.realSrcList
    }
  }, [_c("div", {
    staticClass: "image-slot",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };