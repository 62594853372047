import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _typeof from "/root/.jenkins/workspace/47.102.216.211-chengjian-frontend-dev/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import axios from 'axios';
import router from '@/router/routers';
import { Notification } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import Config from '@/settings';
import Cookies from 'js-cookie';
import { decryptWhiteList } from "./specialWhitelist";
import { decryptAES } from "./aes";
// 创建axios实例
var service = axios.create({
  // baseURL: process.env.NODE_ENV === "local" ? "/" : process.env.VUE_APP_BASE_API, // api 的 base_url
  baseURL: process.env.NODE_ENV === 'local' ? '/' : process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});

function deepDecrypt(data, keysToDecrypt) {
  // 基本类型直接返回
  if (_typeof(data) !== 'object' || data === null) return data;

  // 如果是数组，递归解密每个元素
  if (Array.isArray(data)) {
    return data.map(function (item) {
      return deepDecrypt(item, keysToDecrypt);
    });
  }

  // 如果是对象，遍历其键
  Object.keys(data).forEach(function (key) {
    // 检查是否需要解密该字段
    if (keysToDecrypt.includes(key) && typeof data[key] === 'string') {
      data[key] = decryptAES(data[key]); // 解密字段
    } else if (_typeof(data[key]) === 'object' && data[key] !== null) {
      // 如果是嵌套对象或数组，递归处理
      data[key] = deepDecrypt(data[key], keysToDecrypt);
    }
  });
  return data;
}
// request拦截器
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  if (config.ContentType) {
    config.headers['Content-Type'] = config.ContentType;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  if (response.data.data === undefined) {
    // 兼容导出返回
    return response.data;
  }
  if (response.data.status !== '0') {
    Notification.error({
      title: response.data.msg,
      duration: 5000
    });
    return Promise.reject(response);
  }
  var requestUrl = response.config.url;
  var urlInWhitelist = Object.keys(decryptWhiteList).find(function (whitelistUrl) {
    var flag = requestUrl.includes(whitelistUrl) || whitelistUrl.includes(requestUrl);
    return flag;
  });
  if (urlInWhitelist) {
    var keysToDecrypt = decryptWhiteList[urlInWhitelist];
    console.log("\uD83D\uDC74request 56 requestUrl ===>", requestUrl, urlInWhitelist, keysToDecrypt);
    // 使用 deepDecrypt 进行深度解密
    response.data.data = deepDecrypt(response.data.data, keysToDecrypt);
  }
  return response.data.data;
}, function (error) {
  if (error.response.data.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data.data, 'utf-8');
    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).message;
      Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    var code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        });
        return Promise.reject(error);
      }
    }
    console.log(code);
    if (code) {
      if (code === 401) {
        if (!getToken()) {
          return;
        }
        store.dispatch('LogOut').then(function () {
          // 用户登录界面提示
          Cookies.set('point', 401);
          location.reload();
        });
      } else if (code === 403) {
        router.push({
          path: '/401'
        });
      } else {
        var errorMsg = error.response.data.msg;
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          });
        }
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
export default service;